import React from 'react'
import CCURoot from './ccus/CCURoot'
import ErrorPage from './ErrorPage'
import { Route, Switch } from 'react-router-dom'

export default function Routes() {
	return (
		<div>
			<Switch>
				<Route exact path='/'>
					<CCURoot />
				</Route>
				<ErrorPage />
			</Switch>
		</div>
	)
}
