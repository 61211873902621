import sendRSI from 'functions/extension'
import React from 'react'
import { useEffect, useState } from 'react'
import CCUChart from './CCUChart'
import useContextCCU from './CCUContext'
import CCULoadPledges from './CCULoadPledges'
// import CCUExtension from './CCUExtension'
import useRSIContext from './RSIContext'

export default function CCUSelector() {
	const { rsiData } = useRSIContext()
	const { ccuData } = useContextCCU()

	console.log({ rsiData, pledges: ccuData.pledges })

	return ccuData?.pledges?.length ? <CCUChart /> : <CCULoadPledges />
}
