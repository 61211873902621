import React, { memo, useCallback, useMemo, useState } from 'react'
import { CardContent, Typography, CardActions, Card } from '@material-ui/core'

import Box from '@material-ui/system/Box'

import useContextCCU from './CCUContext'
import useShipContext from './CCUShipContext'

export default function CCUChart() {
	const { ccuData } = useContextCCU()
	const { shipData } = useShipContext()

	const { scale, minimise, stack, pledges } = ccuData
	const { ships } = shipData

	const upgrades = useMemo(() => pledges?.filter(pledge => pledge.ccu || pledge.ship), [pledges])

	const grids = useMemo(() => {
		if (!upgrades || !ships) return
		const ccusCopy = JSON.parse(JSON.stringify(upgrades))
		const ccuList = !stack
			? ccusCopy
			: ccusCopy.filter((ccu, i, arr) => {
					const index = arr.findIndex(item => item.from?.id === ccu.from?.id && item.to?.id === ccu.to?.id)
					const dupe = index !== i
					if (dupe) {
						if (arr[index].qty) {
							arr[index].qty++
						} else {
							arr[index].qty = 2
						}
					}
					return !dupe
			  })

		const shipLists = []

		let maxValue = 0
		ccuList.forEach(ccu => {
			const { from, to } = ccu
			const shipFrom = ships?.find(ship => ship?.id === from?.id)
			const shipTo = ships?.find(ship => ship?.id === to?.id)

			if (!shipFrom || !shipTo) return

			ccu.from = JSON.parse(JSON.stringify(shipFrom))
			ccu.to = JSON.parse(JSON.stringify(shipTo))
			if (shipFrom.msrp > shipTo.msrp) {
				const shipFromPrice = shipFrom.msrp
				const shipToPrice = shipTo.msrp
				ccu.from.msrp = shipToPrice
				ccu.to.msrp = shipFromPrice
			}

			maxValue = Math.max(maxValue, shipFrom.msrp)
			maxValue = Math.max(maxValue, shipTo.msrp)

			if (shipLists.length === 0) return shipLists.push([ccu])

			outerloop: for (let i = 0; i < shipLists.length; i++) {
				const list = shipLists[i]
				for (let l = 0; l < list.length; l++) {
					const listedFrom = ships.find(ship => ship.id === list[l].from.id)
					const listedTo = ships.find(ship => ship.id === list[l].to.id)
					if (shipFrom.msrp < listedFrom.msrp && shipTo.msrp <= listedFrom.msrp && listedFrom.id !== listedTo.id) {
						list.splice(l, 0, ccu)
						break outerloop
					} else if (listedFrom.id === listedTo.id && (shipFrom.msrp < listedFrom.msrp || shipTo.msrp < listedFrom.msrp || shipFrom.msrp < listedTo.msrp || shipTo.msrp < listedTo.msrp)) {
						break //If ccu is cheaper than an owned ship
					} else if (shipFrom.id === shipTo.id && (shipFrom.msrp > listedFrom.msrp || shipTo.msrp > listedFrom.msrp || shipFrom.msrp > listedTo.msrp || shipTo.msrp > listedTo.msrp)) {
						break //if ccu is more expensive than an owned ship
					} else if (
						(listedFrom.msrp < shipFrom.msrp && listedTo.msrp > shipFrom.msrp) ||
						(listedFrom.msrp < shipTo.msrp && listedTo.msrp > shipTo.msrp) ||
						(shipFrom.msrp < listedFrom.msrp && shipTo.msrp > listedFrom.msrp) ||
						(shipFrom.msrp < listedTo.msrp && shipTo.msrp > listedTo.msrp)
					) {
						break
					} else if (shipFrom.id === listedFrom.id && shipTo.id === listedTo.id) {
						break
					} else if (shipFrom.msrp === listedFrom.msrp && shipTo.msrp === listedTo.msrp) {
						break
					}
				}

				const lastListFrom = ships.find(ship => ship.id === list[list.length - 1].from.id)
				const lastListTo = ships.find(ship => ship.id === list[list.length - 1].to.id)
				if (
					(shipFrom.msrp >= lastListTo.msrp || (shipFrom.msrp === lastListFrom.msrp && shipFrom.id === lastListTo.id)) &&
					shipTo.msrp > lastListTo.msrp &&
					!(shipFrom.id === shipTo.id && lastListFrom.id === lastListTo.id)
				) {
					list.push(ccu)
					break
				}

				if (i === shipLists.length - 1) {
					shipLists.push([ccu])
					break
				}
			}
		})

		const grids = {}
		for (let price = 0; price <= maxValue; price += scale * 100) {
			grids[price] = []
			for (let l = 0; l < shipLists.length; l++) {
				const list = shipLists[l]
				grids[price][l] = undefined
				for (let c = 0; c < list.length; c++) {
					const ccu = list[c]
					if (ccu.from.msrp && ccu.to.msrp && ccu.from.msrp === price && ccu.from.msrp === ccu.to.msrp) {
						if (!grids[price][l]) grids[price][l] = []
						grids[price][l].push({ ...ccu, type: 'start' })
					} else if (ccu.from.msrp === price) {
						if (!grids[price][l]) grids[price][l] = []
						grids[price][l].push({ ...ccu, type: 'from' })
					} else if (ccu.to.msrp === price) {
						if (!grids[price][l]) grids[price][l] = []
						grids[price][l].push({ ...ccu, type: 'to' })
					}
				}
			}
		}

		if (minimise) {
			for (const col in grids) {
				if (!grids[col].filter(cell => cell).length) delete grids[col]
			}
		}

		return grids
	}, [stack, minimise, scale, upgrades, ships])

	const Content = memo(() => {
		if (!ships || !upgrades || !grids) return <div>No data</div>
		return (
			<Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
				{Object.entries(grids).map(([key, col]) => (
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							position: 'relative',
							width: theme =>
								(col.reduce(
									(max, row) => Math.max(max, row?.length || 0, row?.reduce((total, ccu) => (total ? total : ccu.from?.id !== ccu.to?.id && ccu.from?.msrp === ccu.to?.msrp ? 2 : 0), 0) || 0),
									0
								) || 1) * 150,
						}}
						key={key}
					>
						<Card
							sx={{
								marginRight: '20px',
								paddingLeft: '10px',
							}}
							elevation={5}
						>
							${key / 100}
						</Card>
						{col.map((row, i) => {
							if (!row) return false
							row = row.sort((a, b) => b.type.localeCompare(a.type)) //sorts the 'to' of a prev ccu and the 'from' of the current ccu.

							return row?.map((ccu, c) => {
								if (ccu.type === 'to') return false
								const widths =
									ccu.from?.id !== ccu.to?.id && ccu.from?.msrp === ccu.to?.msrp
										? 2
										: ccu.type === 'start'
										? 1
										: Object.entries(grids)
												.filter(([key, col]) => key >= ccu.from?.msrp && key < ccu.to?.msrp)
												.reduce(
													(total, [k, c]) =>
														total +
														c.reduce((max, r) => Math.max(max, r?.length || 1, r?.filter(ccu => ccu.from?.id !== ccu.to?.id && ccu.from?.msrp === ccu.to?.msrp).length ? 2 : 0), 0),
													0
												) +
										  1 -
										  row.findIndex(c => c === ccu)

								return (
									<Card
										sx={{
											width: widths ? widths * 150 - 20 : 130,
											display: 'flex',
											height: 100,
											left: row.findIndex(c => c === ccu) * 150,
											top: i * 120 + 40,
											position: 'absolute',
											'&:hover .actions': {
												opacity: 1,
											},
										}}
										elevation={5}
										key={ccu.id}
									>
										<CardContent
											sx={{
												padding: '0 !important',
												flex: 1,
												display: 'flex',
												justifyContent: 'space-between',
												position: 'relative',
											}}
										>
											<Box
												sx={{
													display: 'flex',
													flexDirection: 'column',
													width: ccu.from.id !== ccu.to.id ? '140px' : '130px',
													backgroundImage: theme =>
														`url(${ccu.from.medias.productThumbMediumAndSmall}), ${
															theme.palette.mode === 'dark'
																? `linear-gradient(to right, rgba(0,0,0,0.5) 0%, rgba(0, 0, 0 ,0.5) 100%)`
																: `linear-gradient(to right, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0.5) 100%)`
														}`,
													backgroundBlendMode: 'overlay',
													backgroundSize: '150px auto',
													backgroundPosition: '50%',
													maskImage: widths === 1 ? undefined : `linear-gradient(80deg, black 65%, transparent 90%)`,
													transition: 'all 0.3s ease-in-out',
												}}
											>
												<Typography component='div' m={1.2}>
													{ccu.from.name}
												</Typography>
											</Box>
											{ccu.to.id !== ccu.from.id && (
												<Box
													sx={{
														display: 'flex',
														flexDirection: 'column',
														width: '140px',
														backgroundImage: theme =>
															`url(${ccu.to.medias.productThumbMediumAndSmall}), ${
																theme.palette.mode === 'dark'
																	? `linear-gradient(to left, rgba(0,0,0,0.5) 0%, rgba(0, 0, 0, 0.5) 100%)`
																	: `linear-gradient(to left, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0.5) 100%)`
															}`,
														backgroundBlendMode: 'overlay',
														backgroundSize: '150px auto',
														backgroundPosition: '0',
														maskImage: `linear-gradient(260deg, black 65%, transparent 90%)`,
														transition: 'all 0.3s ease-in-out',
													}}
												>
													<Typography
														component='div'
														m={1.2}
														sx={{
															display: 'flex',
															justifyContent: 'flex-end',
															textAlign: 'right',
														}}
													>
														{ccu.to.name}
													</Typography>
												</Box>
											)}
											{ccu.qty && (
												<CardActions
													className='actions'
													sx={{
														position: 'absolute',
														bottom: '-5px',
														right: '-5px',
														opacity: 0,
														transition: 'all 0.1s linear',
														display: 'flex',
													}}
												>
													<Box p={1}>x{ccu.qty}</Box>
												</CardActions>
											)}
										</CardContent>
									</Card>
								)
							})
						})}
					</Box>
				))}
			</Box>
		)
	}, [grids])

	return <Content />
}
