import React, { useContext, useState } from 'react'
import { makeStyles } from '@material-ui/styles'

import Button from '@material-ui/core/Button'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'

import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

import SideBar from 'components/sidebar/Sidebar'
import useContextCCU from 'components/ccus/CCUContext'
import CCUModal from 'components/ccus/CCUModal'
import { ThemeContext } from 'components/theme/Theme'

import useShipContext from './CCUShipContext'
import sendRSI from '../../functions/extension'
import { urlParser } from '../../utils/helpers'
import { useLocation } from 'react-router-dom'
import CCULoadPledges from './CCULoadPledges'

const stripManufacturer = name => {
	return name?.replace(
		/ ?(?:Robert Space Industries|Anvil(?: ?Aerospace)?|Esperia|Crusader(?: ?Industries)?|Origin(?: ?Jumpworks)?|MISC|Drake(?: ?Interplanetary)?|Aegis(?: ?Dynamics)?|Vanduul|Greycat(?: ?Inductrial)?|Kruger(?: ?Intergalactic)?|Banu|Consolidated Outland|Aopoa|Tumbril|Gatac(?: ?Manufacture)?)/gi,
		''
	)
}

const useStyles = makeStyles(theme => {
	return {
		maxWidth: {
			flex: 1,
		},
		buttonIcon: {
			marginRight: '0.5rem',
		},
	}
})

let ships = []

export default function CCUSideBar() {
	const classes = useStyles()
	const { state: themeState, themeDispatch } = useContext(ThemeContext)
	const location = useLocation()
	const page = location.pathname.split('/')[1]

	const [modalOpen, setModalOpen] = useState(false)

	const {
		ccuData: { minimise, stack },
		ccuDispatch,
	} = useContextCCU()

	const changeMinimise = e => {
		e.preventDefault()
		ccuDispatch({ action: 'changeMinimise' })
	}

	const changeStack = e => {
		e.preventDefault()
		ccuDispatch({ action: 'changeStack' })
	}

	const closeModal = () => {
		setModalOpen(false)
	}

	const openModal = () => {
		setModalOpen(true)
	}

	const changeTheme = e => {
		e.preventDefault()
		themeDispatch({ action: 'switch' })
	}

	const shipUpgrades = () => {
		const payload = {
			operationNAme: 'user',
			variables: { fromFilters: [], toFilters: [] },
			query: `query user {
				user {
					handle
				}
			}`,
		}

		sendRSI({ payload, url: 'https://robertsspaceindustries.com/pledge-store/api/upgrade/graphql', method: 'POST' }, function (response) {
			console.log(response)
		})
	}

	let pledges = []

	const fetchPledges = ({ page = 1 } = {}) => {
		const url = `https://robertsspaceindustries.com/account/pledges?page=${page}&pagesize=10`

		sendRSI(
			{
				url,
				action: 'fetchHTML',
			},

			function (response) {
				const parser = new DOMParser()
				const html = parser.parseFromString(response, 'text/html')

				html.querySelectorAll('.list-items li').forEach(el => {
					const id = el.querySelector('.js-pledge-id')?.value.trim()
					const name = stripManufacturer(el.querySelector('.js-pledge-name')?.value.trim())

					const value = el.querySelector('.js-pledge-value')?.value.trim()
					const giftable = el.querySelector('.js-gift')
					const meltable = el.querySelector('.js-reclaim')
					const items = el.querySelector('.items')
					const ship = [...(items?.querySelectorAll('.kind') || [])].map(el => el?.innerText.trim()).includes('Ship')
					const lti = [...(items?.querySelectorAll('.title') || [])].map(el => el?.innerText.trim()).includes('Lifetime Insurance')
					const ccu = /^upgrade/i.test(name)
					let [fromName, toName] = ccu
						? name
								.replace(/upgrade - /gi, '')
								.replace(/(?: (?:warbond|standard))? edition/gi, '')
								.split(/ to /i)
						: []

					const shipName = !ship
						? undefined
						: [...items.querySelectorAll('.item')].reduce((prev, item) => {
								console.log(prev, item)
								if (prev || item.querySelector('.kind')?.innerText.trim() === 'Ship') {
									return prev || stripManufacturer(item.querySelector('.title')?.innerText.trim()).trim()
								}
						  }, undefined)

					if (fromName === 'Mercury') fromName = 'Mercury Star Runner'
					if (toName === 'Mercury') toName = 'Mercury Star Runner'

					const fromShipId = ships.find(ship => ship.name === (shipName || fromName))?.id
					const toShipId = ships.find(ship => ship.name === (shipName || toName))?.id

					const pledge = { id, shipName, ship, ccu, name, value, giftable: !!giftable, meltable: !!meltable, lti }
					if (fromShipId) pledge.from = { id: fromShipId }
					if (toShipId) pledge.to = { id: toShipId }

					pledges.push(pledge)
				})

				const nextPage = html.querySelector('.pager')?.querySelector('.gt')?.getAttribute('href')
				if (nextPage) {
					const { params } = urlParser(nextPage)
					if (params.page) fetchPledges({ page: params.page })
				} else {
					ccuDispatch({ action: 'loadPledges', pledges })
					pledges = []
				}
			}
		)
	}

	const fetchUser = () => {
		const editorExtensionId = 'mlklnelincjfdmedillihdbiohlmolmn'

		chrome.runtime.sendMessage(
			editorExtensionId,
			{
				url: 'https://robertsspaceindustries.com/api/spectrum/auth/identify',
				payload: {},
			},
			function (response) {
				console.log(response?.data?.member?.displayname, response)
			}
		)
	}

	const ping = () => {
		sendRSI(
			{
				action: 'ping',
			},
			reply => console.log(reply)
		)
	}

	return (
		<SideBar>
			<CCUModal openModel={openModal} closeModal={closeModal} open={modalOpen} />
			<List>
				<ListItem button key='minimise' onClick={changeMinimise}>
					<FormGroup>
						<FormControlLabel control={<Switch checked={minimise} onChange={changeMinimise} />} label='Minimise Graph' />
					</FormGroup>
				</ListItem>
				<ListItem button key='stack' onClick={changeStack}>
					<FormGroup>
						<FormControlLabel control={<Switch checked={stack} onChange={changeStack} />} label='Stack Similar CCUs' />
					</FormGroup>
				</ListItem>
				<ListItem button key='theme' onClick={changeTheme}>
					<FormGroup>
						<FormControlLabel control={<Switch checked={themeState.dark} onChange={changeTheme} />} label='Dark Mode' />
					</FormGroup>
				</ListItem>
				{page === 'custom' && (
					<ListItem>
						<Button className={classes.maxWidth} variant='contained' onClick={openModal}>
							<AddCircleOutlineIcon className={classes.buttonIcon}></AddCircleOutlineIcon> Add CCU
						</Button>
					</ListItem>
				)}
				{!page && <ListItem>{<CCULoadPledges />}</ListItem>}
			</List>
		</SideBar>
	)
}
