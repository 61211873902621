import { Button } from '@material-ui/core'
import React, { useState } from 'react'
import useContextCCU from './CCUContext'
import useRSIContext from './RSIContext'

export default function CCULoadPledges(props) {
	const { ccuData, ccuActions } = useContextCCU()
	const { rsiData, rsiActions } = useRSIContext()
	const { pledges } = ccuData
	const [loadingPledges, setLoadingPledges] = useState(false)

	const loadPledges = () => {
		setLoadingPledges(true)
		ccuActions.fetchPledges().then(() => {
			setLoadingPledges(false)
		})
	}

	return (
		<>
			{rsiData.extension?.loaded && rsiData.user?.token && (
				<Button disabled={loadingPledges} style={{ flex: 1 }} variant='contained' onClick={loadPledges} {...props}>
					{pledges?.length ? 'Reload' : 'Load'} Pledges
				</Button>
			)}
			{!rsiData.extension?.loaded && (
				<div style={{ display: 'flex', flexDirection: 'column', flex: 1, gap: '15px' }}>
					<div>Please install The Verse Extension to get the best experiance</div>
					<Button variant='contained' target='_blank' href='https://chrome.google.com/webstore/detail/the-verse-extension/omjhdijpgnmecceilnmnljohogjgipcf/'>
						The Verse Extension
					</Button>
				</div>
			)}
			{rsiData.extension?.loaded && !rsiData.user?.token && (
				<Button style={{ flex: 1 }} variant='contained' target='_blank' href='https://robertsspaceindustries.com/connect'>
					Login to RSI
				</Button>
			)}
		</>
	)
}
