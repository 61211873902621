import React, { createContext, useMemo, useEffect } from 'react'
import { ThemeProvider, createTheme } from '@material-ui/core/styles'
import { useImmerReducer } from 'use-immer'

/*  The Theme Context for the global theme. Includes the reducer to change the theme.
 *
 */

const themeReducer = (state, { action, dark }) => {
	switch (action) {
		case 'theme': {
			if (dark === 'light') state.dark = false
			return
		}
		case 'switch': {
			state.dark = !state.dark
			if (state.dark) localStorage.theme = 'dark'
			else localStorage.theme = 'light'
			return
		}
		default: {
			return state
		}
	}
}

export const ThemeContext = createContext()

export default function ThemeContainer({ children }) {
	const [state, themeDispatch] = useImmerReducer(themeReducer, { dark: true })

	const theme = useMemo(
		() =>
			createTheme({
				palette: {
					mode: state.dark ? 'dark' : 'light',
				},
			}),
		[state]
	)

	useEffect(() => {
		const dark = localStorage.theme
		themeDispatch({ action: 'theme', dark })
	}, [themeDispatch])

	return (
		<ThemeContext.Provider value={{ state, themeDispatch }}>
			<ThemeProvider theme={theme}>{children}</ThemeProvider>
		</ThemeContext.Provider>
	)
}
