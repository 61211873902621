import './App.css'
import { BrowserRouter as Router } from 'react-router-dom'

import Theme from 'components/theme/Theme'
import ShipGrade from 'components/ShipGrade'
import { CCUScope } from 'components/ccus/CCUContext'
import { CCUShipScope } from 'components/ccus/CCUShipContext'
import { RSIScope } from 'components/ccus/RSIContext'

function App() {
	return (
		<div className='App'>
			<Router>
				<RSIScope>
					<CCUShipScope>
						<CCUScope>
							<Theme>
								<ShipGrade />
							</Theme>
						</CCUScope>
					</CCUShipScope>
				</RSIScope>
			</Router>
		</div>
	)
}

export default App
