import sendRSI from 'functions/extension'
import React, { createContext, useEffect } from 'react'
import { useContext } from 'react'
import { useImmerReducer } from 'use-immer'
import useRSIContext from './RSIContext'

const shipReducer = (state, { action, data }) => {
	switch (action) {
		case 'addShips': {
			const ccuData = JSON.parse(localStorage.ccuData || `{}`)
			ccuData.ships = data.ships
			ccuData.manufacturers = data.manufacturers
			localStorage.ccuData = JSON.stringify(ccuData)
			return { ships: data.ships, manufacturers: data.manufacturers }
		}
		default:
			return state
	}
}

export const CCUShipContext = createContext()

export const useShipContext = () => useContext(CCUShipContext)

const initialState = [{ ships: [], manufacturers: [] }]

export function CCUShipScope({ children }) {
	const [shipData, shipDispatch] = useImmerReducer(shipReducer, initialState)
	const { rsiData, rsiDispatch } = useRSIContext()

	useEffect(() => {
		if (!rsiData?.extension?.loaded) return
		const payload = {
			operationName: 'initShipUpgrade',
			variables: {},
			query: `query initShipUpgrade {
            ships {
               id
               name
               medias {
                  productThumbMediumAndSmall
                  slideShow
               }
               manufacturer {
                  id
                  name
               }
               focus
               type
               flyableStatus
               owned
               msrp
               link
               skus {
                  id
                  title
                  available
                  price
                  body
                  unlimitedStock
                  availableStock
               }
            }
            manufacturers {
               id
               name
               medias {
                  productThumbMediumAndSmall
               }
            }
            app {
               version
               env
               cookieName
               sentryDSN
               pricing {
                  currencyCode
                  currencySymbol
                  exchangeRate
                  taxRate
                  isTaxInclusive
               }
               mode
               isAnonymous
               buyback {
                  credit
               }
            }
         }`,
		}

		sendRSI(
			{
				payload,
				url: 'https://robertsspaceindustries.com/pledge-store/api/upgrade/graphql',
				method: 'POST',
			},
			function (res) {
				const error = !res?.data?.ships?.length
				const ships = !error ? [...res.data.ships.map(ship => (ship.name === 'Mercury' ? { ...ship, name: 'Mercury Star Runner' } : ship))] : JSON.parse(localStorage.ccuData || `{}`)
				shipDispatch({ action: 'addShips', data: { ships, manufacturers: res.data.manufacturers } })
			}
		)
	}, [shipDispatch, rsiData, rsiDispatch])

	return <CCUShipContext.Provider value={{ shipData, shipDispatch }}>{children}</CCUShipContext.Provider>
}

export default useShipContext
