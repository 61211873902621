import React from 'react'
import Button from '@material-ui/core/Button'
import { styled } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	'& .MuDialogContent-root': {
		padding: theme.spacing(2),
	},
	'& .MuDialogActions-root': {
		padding: theme.spacing(1),
	},
}))

const BootstrapDialogTitle = props => {
	const { children, onClose, ...other } = props

	return (
		<DialogTitle sx={{ m: 0, p: 2 }} {...other}>
			{children}
			{onClose ? (
				<IconButton
					aria-label='close'
					onClick={onClose}
					sx={{
						position: 'absolute',
						right: 8,
						top: 8,
						color: theme => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
			) : null}
		</DialogTitle>
	)
}

export default function Modal({ body, buttons, title, onClose, open, ...props }) {
	return (
		<BootstrapDialog onClose={onClose} aria-labelledby='customized-dialog-title' open={open} {...props}>
			<BootstrapDialogTitle id='customized-dialog-title' onClose={onClose}>
				{title}
			</BootstrapDialogTitle>
			<DialogContent dividers>{body}</DialogContent>
			<DialogActions>
				{buttons?.map(button => {
					const { text, ...props } = button
					return (
						<Button key={button.text} {...props}>
							{text}
						</Button>
					)
				})}
			</DialogActions>
		</BootstrapDialog>
	)
}
