import React, { useState } from 'react'

import CssBaseline from '@material-ui/core/CssBaseline'

import useMediaQuery from '@material-ui/core/useMediaQuery'

import TopBar from 'components/TopBar/TopBar'
import Routes from 'components/Routes'
import MainContent from 'components/MainContent'
import CCUSideBar from 'components/ccus/CCUSideBar'
import Box from '@material-ui/core/Box'

export default function ShipGrade() {
	const desktop = useMediaQuery('(min-width:640px)', { noSsr: true })
	const [isOpen, setIsOpen] = useState(desktop)

	return (
		<Box sx={{ display: 'flex' }}>
			<CssBaseline />
			<TopBar setIsOpen={setIsOpen} />
			{isOpen && <CCUSideBar />}
			<MainContent>
				<Routes />
			</MainContent>
		</Box>
	)
}
