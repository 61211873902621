import sendRSI from 'functions/extension'
import React, { createContext, useEffect } from 'react'
import { useMemo } from 'react'
import { useContext } from 'react'
import { useImmerReducer } from 'use-immer'
import { urlParser } from 'utils/helpers'

import { v4 as uuid } from 'uuid'
import useShipContext from './CCUShipContext'

const stripManufacturer = name => {
	return name?.replace(
		/ ?(?:Robert Space Industries|Anvil(?: ?Aerospace)?|Esperia|Crusader(?: ?Industries)?|Origin(?: ?Jumpworks)?|MISC|Drake(?: ?Interplanetary)?|Aegis(?: ?Dynamics)?|Vanduul|Greycat(?: ?Inductrial)?|Kruger(?: ?Intergalactic)?|Banu|Consolidated Outland|Aopoa|Tumbril|Gatac(?: ?Manufacture)?)/gi,
		''
	)
}

const ccuReducer = (state, { action, ccuData, ccu, id, pledges }) => {
	switch (action) {
		case 'loadCCUs': {
			return { ...state, ...ccuData }
		}
		case 'loadPledges': {
			const ccuData = JSON.parse(localStorage.ccuData || `{}`)
			delete ccuData.pledes
			ccuData.pledges = pledges
			localStorage.ccuData = JSON.stringify(ccuData)
			return ccuData
		}
		case 'addCCU': {
			const fromId = ccu.from.id
			const toId = ccu.to.id
			const price = ccu.price
			const insurance = ccu.insurance
			const ccuNew = { id: uuid(), from: { id: fromId }, to: { id: toId } }
			if (price) ccuNew.price = price
			if (insurance) ccuNew.insurance = insurance
			state.ccus.push(ccuNew)
			const ccuData = JSON.parse(localStorage.ccuData || `{}`)
			localStorage.ccuData = JSON.stringify({ ...state, ...ccuData })
			return
		}
		case 'editCCU': {
			const fromId = ccu.from.id
			const toId = ccu.to.id
			const price = ccu.price
			const insurance = ccu.insurance
			const i = state.ccus.findIndex(c => c.id === ccu.id)
			state.ccus[i] = { ...state.ccus[i], from: { id: fromId }, to: { id: toId } }
			if (insurance) state.ccus[i].insurance = insurance
			else if (!insurance) delete state.ccus[i].insurance
			if (price) state.ccus[i].price = price
			else if (!price || price === '0') delete state.ccus[i].price
			const ccuData = JSON.parse(localStorage.ccuData || `{}`)
			localStorage.ccuData = JSON.stringify({ ...state, ...ccuData })
			return
		}
		case 'deleteCCU': {
			const i = state.ccus.findIndex(c => c.id === id)
			state.ccus.splice(i, 1)
			const ccuData = JSON.parse(localStorage.ccuData || `{}`)
			localStorage.ccuData = JSON.stringify({ ...state, ...ccuData })
			return
		}
		case 'changeMinimise': {
			state.minimise = !state.minimise
			const ccuData = JSON.parse(localStorage.ccuData || `{}`)
			localStorage.ccuData = JSON.stringify({ ...state, ...ccuData, minimise: state.minimise })
			return
		}
		case 'changeStack': {
			state.stack = !state.stack
			const ccuData = JSON.parse(localStorage.ccuData || `{}`)
			localStorage.ccuData = JSON.stringify({ ...state, ...ccuData, stack: state.stack })
			return
		}
		default:
			return state
	}
}

export const CCUContext = createContext()

export const useContextCCU = () => useContext(CCUContext)

const initialState = {
	ccus: [],
	scale: 5,
	minimise: true,
	stack: true,
	verion: '2.0.0',
}

export function CCUScope({ children }) {
	const [ccuData, ccuDispatch] = useImmerReducer(ccuReducer, initialState)
	const { shipData } = useShipContext()
	const { ships } = shipData

	let pledges = []

	const fetchPledges = ({ page = 1 } = {}) => {
		return new Promise((resolve, reject) => {
			const url = `https://robertsspaceindustries.com/account/pledges?page=${page}&pagesize=10`

			sendRSI(
				{
					url,
					action: 'fetchHTML',
				},

				function (response) {
					const parser = new DOMParser()
					const html = parser.parseFromString(response, 'text/html')

					html.querySelectorAll('.list-items li').forEach(el => {
						console.log(el)
						const id = el.querySelector('.js-pledge-id')?.value.trim()
						const name = stripManufacturer(el.querySelector('.js-pledge-name')?.value.trim())

						const value = el.querySelector('.js-pledge-value')?.value.trim()
						const giftable = el.querySelector('.js-gift')
						const meltable = el.querySelector('.js-reclaim')
						const items = el.querySelector('.items')
						const ship = [...(items?.querySelectorAll('.kind') || [])].map(el => el?.innerText.trim()).includes('Ship')
						const lti = [...(items?.querySelectorAll('.title') || [])].map(el => el?.innerText.trim()).includes('Lifetime Insurance')
						const ccu = /^upgrade/i.test(name)
						let [fromName, toName] = ccu
							? name
									?.replace(/upgrade - /gi, '')
									?.replace(/(?:(?: BIS)? (?:warbond|standard))? edition/gi, '')
									?.split(/ to /i)
							: []

						const shipName = !ship
							? undefined
							: [...items.querySelectorAll('.item')].reduce((prev, item) => {
									console.log(prev, item)
									if (prev || item.querySelector('.kind')?.innerText.trim() === 'Ship') {
										return prev || stripManufacturer(item.querySelector('.title')?.innerText.trim()).trim()
									}
							  }, undefined)

						if (fromName === 'Mercury') fromName = 'Mercury Star Runner'
						if (toName === 'Mercury') toName = 'Mercury Star Runner'
						console.log({ ships })
						const fromShipId = ships.find(ship => ship.name === (shipName || fromName))?.id
						const toShipId = ships.find(ship => ship.name === (shipName || toName))?.id

						const pledge = { id, shipName, ship, ccu, name, value, giftable: !!giftable, meltable: !!meltable, lti }
						if (fromShipId) pledge.from = { id: fromShipId }
						if (toShipId) pledge.to = { id: toShipId }
						pledges.push(pledge)
					})

					const nextPage = html.querySelector('.pager')?.querySelector('.gt')?.getAttribute('href')
					if (nextPage) {
						const { params } = urlParser(nextPage)
						if (params.page) fetchPledges({ page: params.page })
					} else {
						ccuDispatch({ action: 'loadPledges', pledges })
						pledges = []
						resolve()
					}
				}
			)
		})
	}

	const ccuActions = useMemo(
		() => ({
			fetchPledges,
		}),
		[fetchPledges]
	)

	useEffect(() => {
		const ccuData = JSON.parse(localStorage.ccuData || `{}`)
		ccuData.ccus = ccuData.ccus?.filter(ccu => ccu.from && ccu.to) || []
		ccuDispatch({ action: 'loadCCUs', ccuData })
	}, [ccuDispatch])

	return <CCUContext.Provider value={{ ccuData, ccuDispatch, ccuActions }}>{children}</CCUContext.Provider>
}

export default useContextCCU
