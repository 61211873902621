import React from 'react'
import { Route, Switch } from 'react-router-dom'
import CCUChart from './CCUChart'
import CCUCustom from './CCUCustom'
import CCUSelector from './CCUSelector'

export default function CCURoot() {
	return (
		<Switch>
			<Route path='/custom'>
				<CCUCustom />
			</Route>
			<Route path='/'>
				<CCUSelector />
			</Route>
		</Switch>
	)
}
