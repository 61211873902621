import React, { useEffect, useState } from 'react'

import MenuItem from '@material-ui/core/MenuItem'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'

import shipdata from 'utils/shipdata'
import Modal from 'components/modals/Modal'
import useContextCCU from 'components/ccus/CCUContext'
import { Grid, TextField, Typography } from '@material-ui/core'

export default function CCUModal({ openModel, closeModal, open, editCCU }) {
	const [selected, setSelected] = useState({ from: '', to: '', price: '', insurance: '' })
	const [failedValidation, setFailedValidation] = useState({})

	const {
		ccuData: { ccus },
		ccuDispatch,
	} = useContextCCU()

	useEffect(() => {
		if (!open || !editCCU) return
		const { from, to, price, insurance = '' } = ccus.find(ccu => ccu.id === editCCU)
		setSelected(selected => ({ ...selected, from: from.id, to: to.id, price, insurance }))
	}, [editCCU, ccus, open])

	const saveCCU = () => {
		if (!selected.from) setFailedValidation(failedValidation => ({ ...failedValidation, selectedFrom: 'Required' }))
		if (!selected.to) setFailedValidation(failedValidation => ({ ...failedValidation, selectedTo: 'Required' }))
		if (!selected.from || !selected.to) return
		const shipFrom = shipdata.find(ship => ship.id === selected.from)
		const shipTo = shipdata.find(ship => ship.id === selected.to)
		if (!editCCU) ccuDispatch({ action: 'addCCU', ccu: { from: shipFrom, to: shipTo, price: selected.price, insurance: selected.insurance } })
		else ccuDispatch({ action: 'editCCU', ccu: { from: shipFrom, to: shipTo, id: editCCU, price: selected.price, insurance: selected.insurance } })
		closeModal()
	}

	const onChange = ({ target: { name, value } }) => {
		setFailedValidation(validation => {
			if (name && validation[name] && value) {
				delete validation[name]
			}
			return validation
		})
		setSelected(selected => ({ ...selected, [name]: value }))
	}

	return (
		<Modal
			open={open}
			onClose={closeModal}
			title={editCCU ? `Edit CCU` : `Add CCU`}
			fullWidth={true}
			buttons={[
				{ text: 'Cancel', onClick: closeModal },
				{ text: 'Save', onClick: saveCCU, variant: 'contained' },
			]}
			sx={{
				width: theme => (theme.breakpoints.up('sm') ? 1 : 1 / 4),
			}}
			body={
				<>
					<Typography px={2} py={1}>
						If you own a ship please select the same From and To for the CCU
					</Typography>
					<Grid container>
						<Grid item xs={12} sm={6} sx={{ display: 'flex' }}>
							<FormControl sx={{ m: 1, minWidth: 120, flex: 1 }}>
								<InputLabel htmlFor='select-ship-from'>Ship From</InputLabel>
								<Select labelId='select-ship-from' name='from' value={selected.from} onChange={onChange} input={<OutlinedInput label='Ship From' />} error={failedValidation.selectedFrom}>
									<MenuItem value=''>
										<em>None</em>
									</MenuItem>
									{shipdata
										.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
										.map(ship => {
											return (
												<MenuItem value={ship.id} key={ship.id}>
													{ship.name}
												</MenuItem>
											)
										})}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={6} sx={{ display: 'flex' }}>
							<FormControl sx={{ m: 1, minWidth: 120, flex: 1 }}>
								<InputLabel htmlFor='select-ship-to'>Ship To</InputLabel>
								<Select labelId='select-ship-to' name='to' value={selected.to} onChange={onChange} input={<OutlinedInput label='Ship To' />} error={failedValidation.selectedTo}>
									<MenuItem value=''>
										<em>None</em>
									</MenuItem>
									{shipdata
										.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
										.map(ship => {
											return (
												<MenuItem value={ship.id} key={ship.id}>
													{ship.name}
												</MenuItem>
											)
										})}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={6} sx={{ display: 'flex' }}>
							<FormControl sx={{ m: 1, minWidth: 120, flex: 1 }}>
								<InputLabel htmlFor='select-ship-to'>Insurance</InputLabel>
								<Select labelId='select-ship-to' name='insurance' value={selected.insurance} onChange={onChange} input={<OutlinedInput label='Ship To' />}>
									<MenuItem value=''>
										<em>None</em>
									</MenuItem>
									{[
										{ value: 'LTI', name: 'LTI' },
										{ value: '120m', name: '120 Months' },
										{ value: '72m', name: '72 Months' },
										{ value: '60m', name: '60 Months' },
										{ value: '48m', name: '48 Months' },
										{ value: '36m', name: '36 Months' },
										{ value: '12m', name: '12 Months' },
										{ value: '6m', name: '6 Months' },
									].map(ship => {
										return (
											<MenuItem value={ship.value} key={ship.value}>
												{ship.name}
											</MenuItem>
										)
									})}
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={6} sx={{ display: 'flex' }}>
							<FormControl sx={{ m: 1, minWidth: 120, flex: 1 }}>
								<TextField id='outlined-basic' name='price' value={selected.price} onChange={onChange} label='Price' variant='outlined' />
							</FormControl>
						</Grid>
					</Grid>
				</>
			}
		/>
	)
}
