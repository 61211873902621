import React from 'react'
import { makeStyles } from '@material-ui/styles'

import Drawer from '@material-ui/core/Drawer'

import Toolbar from '@material-ui/core/Toolbar'
import useMediaQuery from '@material-ui/core/useMediaQuery'

//Default sidebar to be used elsewhere. All children passed in will be rendered within the sidebar.
export default function SideBar({ children }) {
	const desktop = useMediaQuery('(min-width:640px)', { noSsr: true })

	const drawerWidth = desktop ? 250 : '100%'

	const useStyles = makeStyles(theme => {
		return {
			buttonGroup: {
				padding: '1.4rem',
			},
			drawer: {
				width: drawerWidth,
				flexShrink: 0,
			},
			drawerPaper: {
				backgroundColor: theme.palette.mode === 'dark' ? '#333333' : '#dedede',
				width: drawerWidth,
			},
			drawerContainer: {
				display: 'flex',
				flexDirection: 'column',
				overflow: 'auto',
			},
		}
	})

	const classes = useStyles()
	return (
		<Drawer
			className={classes.drawer}
			variant='permanent'
			sx={{
				width: drawerWidth,
				flexShrink: 0,
				[`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box', backgroundColor: theme => (theme.palette.mode === 'dark' ? '#333333' : '#dedede') },
			}}
		>
			<Toolbar />
			<div className={classes.drawerContainer}>{children}</div>
		</Drawer>
	)
}
