const shipdata = [
	{
		name: 'Aurora ES',
		price: 20,
		id: '8b7dab20-757d-49ea-b7ae-106f949729ab',
		img: 'aurora_es.jpg',
	},
	{
		name: 'P-52 Merlin',
		price: 25,
		id: 'e77899c0-bb8c-42e9-85a1-7c94059eafbf',
		img: 'p52-merlin.jpg',
	},
	{
		name: 'Aurora MR',
		price: 30,
		id: '0ac3b10a-db69-4f6d-b6ba-350328fb2aed',
		img: 'aurora_mr.jpg',
	},
	{
		name: 'Mustang Alpha',
		price: 30,
		id: '9373baa9-ed02-4056-8c6b-ca113ec40c31',
		img: 'mustang_alpha.jpg',
	},
	{
		name: 'Mustang Alpha Vindicator',
		price: 30,
		id: '72610ec0-f66d-4514-9fa6-174232bbff48',
		img: 'mustang_alpha_vindicator.png',
	},
	{
		name: 'Aurora LX',
		price: 35,
		id: '1781e82e-5483-4075-8ff0-74e088f9fbfd',
		img: 'aurora_lx.jpg',
	},
	{
		name: 'P72 Archimedes',
		price: 35,
		id: '677a9e15-76ac-4f82-b447-4b49d095c146',
		img: 'p72_archimedes.jpg',
	},
	{
		name: 'MPUV Cargo',
		price: 35,
		id: 'cd9965f3-c218-43cb-bee6-9e6ef395a075',
		img: 'mpuv_cargo.jpg',
	},
	{
		name: 'Ranger RC',
		price: 35,
		id: '001be59e-434d-486d-bac2-602074d2bbb4',
		img: 'ranger_rc.jpg',
	},
	{
		name: 'Ranger CV',
		price: 35,
		id: '94cdb993-bdd1-4323-936f-4023695cb835',
		img: 'ranger_cv.jpg',
	},
	{
		name: 'Aurora LN',
		price: 40,
		id: 'caf123c1-e92a-4b1a-9fe0-33e6f07d8747',
		img: 'aurora_ln.jpg',
	},
	{
		name: 'Mustang Beta',
		price: 40,
		id: 'e36f0a27-9c0d-409b-b889-2c7268173572',
		img: 'mustang_beta.jpg',
	},
	{
		name: 'Dragonfly Yellowjacket',
		price: 40,
		id: 'c8652620-85f2-42dc-bdaf-193da3cb857a',
		img: 'dragonfly_yellowjacket.jpg',
	},
	{
		name: 'Dragonfly Black',
		price: 40,
		id: '47e3c800-c797-4e20-a123-5bdfbd69347b',
		img: 'dragonfly_black.jpg',
	},
	{
		name: 'MPUV Personnel',
		price: 40,
		id: '8c50c3f1-d9e0-431e-9420-51cef85da808',
		img: 'mpuv_personnel.jpg',
	},
	{
		name: 'X1 Base',
		price: 40,
		id: '23f161cf-7972-4329-b12b-efff10afb26b',
		img: 'x1_base.png',
	},
	{
		name: 'Ranger TR',
		price: 40,
		id: '19cf13db-44be-411d-af0e-5ceb29f8c271',
		img: 'ranger_tr.jpg',
	},
	{
		name: 'P72 Archimedes Emerald',
		price: 40,
		id: '3d7c2988-3785-4331-aa5b-3a1f31c9e2d5',
		img: 'p72_archimedes_emerald.jpg',
	},
	{
		name: 'Aurora CL',
		price: 45,
		id: '46dfc864-ca1c-4f31-8260-a74f622425eb',
		img: 'aurora_cl.jpg',
	},
	{
		name: 'Nox',
		price: 45,
		id: 'd794fa86-4847-4cc2-b3e8-02cff7d6666c',
		img: 'nox.jpg',
	},
	{
		name: 'Nox Kue',
		price: 45,
		id: '3c16f48a-2163-43aa-bb0b-d01d26586166',
		img: 'nox_kue.jpg',
	},
	{
		name: 'X1 Velocity',
		price: 45,
		id: '1a92fa89-1d22-4cef-9d73-ea0505c78f85',
		img: 'x1_velocity.png',
	},
	{
		name: 'C8X Pisces Expedition',
		price: 45,
		id: '2c67d520-6e47-4b4d-a9e9-7613d377ee62',
		img: 'c8x_pisces_expedition.jpg',
	},
	{
		name: '85X',
		price: 50,
		id: '2be5bb99-62f0-4f28-833c-f58f21c60dc0',
		img: '85x.jpg',
	},
	{
		name: 'Ursa Rover',
		price: 50,
		id: '188b797c-7e8a-43d9-80f9-e42be2291716',
		img: 'ursa_rover.jpg',
	},
	{
		name: 'X1 Force',
		price: 50,
		id: '580b498d-5aa7-4092-91be-b8ac2ea1d660',
		img: 'x1_force.png',
	},
	{
		name: '100i',
		price: 50,
		id: '2a418da2-6771-41f9-9354-b24c6b968242',
		img: '100i.jpg',
	},
	{
		name: 'Mustang Gamma',
		price: 55,
		id: 'b8125285-feee-4324-ae94-036e569d26ea',
		img: 'mustang_gamma.jpg',
	},
	{
		name: 'Mustang Omega',
		price: 55,
		id: 'a5113c73-c664-471c-b544-5e3fbe86bacb',
		img: 'mustang_omega.jpg',
	},
	{
		name: 'Avenger Titan',
		price: 55,
		id: '50b01606-caed-4748-b408-1b75163a3542',
		img: 'avenger_titan.jpg',
	},
	{
		name: 'Cyclone',
		price: 55,
		id: '7b466339-8480-451d-85ea-1d3a64db837d',
		img: 'cyclone.jpg',
	},
	{
		name: 'Ursa Rover Fortuna',
		price: 55,
		id: 'e88b9b69-4f3a-417a-b165-82b710651a11',
		img: 'ursa_rover_fortuna.jpg',
	},
	{
		name: 'ROC',
		price: 55,
		id: 'abaca37d-008a-425a-a960-ba2cd8c7518d',
		img: 'roc.jpg',
	},
	{
		name: '300i',
		price: 60,
		id: 'c0ddf12e-8f96-4026-af3a-77ee0b67eb9d',
		img: '300i.jpg',
	},
	{
		name: 'Hull A',
		price: 60,
		id: '5903a226-d810-4500-adb6-f8b6603818e8',
		img: 'hull_a.jpg',
	},
	{
		name: 'Avenger Stalker',
		price: 60,
		id: 'ec3f3860-6a00-473a-9404-98f50d9b734d',
		img: 'avenger_stalker.jpg',
	},
	{
		name: '125a',
		price: 60,
		id: '80a3622b-f124-4420-aacc-732ef5ac78ea',
		img: '125a.jpg',
	},
	{
		name: 'G12',
		price: 60,
		id: 'd345477d-b8b1-4694-b389-7fda1b7e07af',
		img: 'g12.jpg',
	},
	{
		name: 'G12R',
		price: 60,
		id: '4afc7d03-edbb-49f5-9640-b4605964d849',
		img: 'g12_r.jpg',
	},
	{
		name: '315p',
		price: 65,
		id: 'd1148665-84ff-4fdd-963d-81560b09725f',
		img: '315p.jpg',
	},
	{
		name: 'Mustang Delta',
		price: 65,
		id: '5bd7501c-e31e-460c-b804-86886c3456e1',
		img: 'mustang_delta.jpg',
	},
	{
		name: 'Reliant Kore',
		price: 65,
		id: '89670baf-8f77-4d4d-81ac-5fbe1d17faef',
		img: 'reliant_kore.jpg',
	},
	{
		name: 'Cylone TR',
		price: 65,
		id: 'a1c04c87-b0a6-4e56-a334-ebd1286f59a5',
		img: 'cyclone_tr.jpg',
	},
	{
		name: 'Cyclone RC',
		price: 65,
		id: '61a5cce4-d4ae-4d24-820f-e8c442008d1f',
		img: 'cyclone_rc.jpg',
	},
	{
		name: 'Cyclone RN',
		price: 65,
		id: '0adc252b-ffd4-43f1-b0fc-54b145d980e0',
		img: 'cyclone_rn.jpg',
	},
	{
		name: '135c',
		price: 65,
		id: '1ad0fdf2-2dac-4a1d-9733-c1b1059c866a',
		img: '135c.jpg',
	},
	{
		name: 'G12A',
		price: 65,
		id: '6b4f9e18-8d9b-4337-934d-60aea19de11e',
		img: 'g12_a.jpg',
	},
	{
		name: '325a',
		price: 70,
		id: '404b2b98-649c-45c1-bbde-4376f9d2603f',
		img: '325a.jpg',
	},
	{
		name: 'Reliant Tana',
		price: 75,
		id: 'b7bca1f7-ac55-4678-aba7-4613a500ded0',
		img: 'reliant_tana.jpg',
	},
	{
		name: 'Avenger Titan Renegade',
		price: 75,
		id: 'b075d11e-d4cb-4412-8023-075b918c67ba',
		img: 'avenger_titan_renegade.jpg',
	},
	{
		name: 'Arrow',
		price: 75,
		id: 'e47a020c-c73b-4fe8-ac14-b17e7ed72f51',
		img: 'arrow.jpg',
	},
	{
		name: 'Cyclone MT',
		price: 75,
		id: '49ca2976-51c3-43ba-a2c7-7352a2788b83',
		img: 'cyclone_mt.jpg',
	},
	{
		name: 'ROC-DS',
		price: 75,
		id: '4c856d97-c4b0-4ccc-a81c-0e7d725b75a1',
		img: 'roc-ds.jpg',
	},
	{
		name: 'Cyclone-AA',
		price: 80,
		id: '4d322d2b-53e0-4945-8404-cd8a9cb67ff6',
		img: 'cyclone_aa.jpg',
	},
	{
		name: 'Nomad',
		price: 80,
		id: 'e0bd312e-87bb-469f-960f-30ec9f125c49',
		img: 'nomad.jpg',
	},
	{
		name: 'Spartan',
		price: 80,
		id: 'c359a5c6-163e-4f66-8a2f-20f73b25346b',
		img: 'spartan.jpg',
	},
	{
		name: 'Herald',
		price: 85,
		id: '29c6e9c1-b3cb-41ef-a468-28464cbd8ce7',
		img: 'herald.jpg',
	},
	{
		name: 'Avenger Warlock',
		price: 85,
		id: '5eb2c169-7404-4340-997a-fcee985c2ec0',
		img: 'avenger_warlock.jpg',
	},
	{
		name: 'Reliant Sen',
		price: 85,
		id: '56f87069-425c-4ae7-8152-15421f64f0be',
		img: 'reliant_sen.jpg',
	},
	{
		name: 'Gladius',
		price: 90,
		id: 'aa7f2522-b87d-4158-9416-3f8772277298',
		img: 'gladius.jpg',
	},
	{
		name: 'Hull B',
		price: 90,
		id: 'b7e0fbf1-f1a2-416d-b5e0-6da0ab36f781',
		img: 'hull_b.jpg',
	},
	{
		name: 'Reliant Mako',
		price: 95,
		id: 'ac507c66-d486-4fcb-a8df-af2186c43da7',
		img: 'reliant_mako.jpg',
	},
	{
		name: 'M50',
		price: 100,
		id: 'f2ed5388-106f-48de-884e-0258d718510b',
		img: 'm50.jpg',
	},
	{
		name: 'Cutless Black',
		price: 100,
		id: '0c219e2f-96c2-46da-8c13-e8a5d62c3b05',
		img: 'cutless_black.jpg',
	},
	{
		name: 'Hawk',
		price: 100,
		id: '001bfb37-942d-49e1-9a08-8621530ce29b',
		img: 'hawk.jpg',
	},
	{
		name: 'Cutless Black Best In Show Edition',
		price: 100,
		id: '8b6704c5-cb8e-4480-8080-81868d8da0fa',
		img: 'cutless_black_best_in_show_edition.jpg',
	},
	{
		name: 'F7C Hornet',
		price: 110,
		id: '0424ebfc-5bab-4b6c-af91-6f4f6d9d7a80',
		img: 'f7c_hornet.jpg',
	},
	{
		name: 'Freelancer',
		price: 110,
		id: '8465ea2c-29c9-4d56-9de6-6fd00cbc1725',
		img: 'freelancer.jpg',
	},
	{
		name: 'Buccaneer',
		price: 110,
		id: '3fd4ffbf-7081-424c-b488-e81f5b04cff2',
		img: 'buccaneer.jpg',
	},
	{
		name: 'Gladius Valiant',
		price: 110,
		id: 'daf31931-4446-4fe2-93e5-8f78f29a2b89',
		img: 'gladius_valiant.jpg',
	},
	{
		name: 'Pirate Gladius',
		price: 110,
		id: 'cbf4d8d6-9fec-4420-8985-9dfc88d8d9a2',
		img: 'pirate_gladius.jpg',
	},
	{
		name: 'Talon',
		price: 115,
		id: 'a863eb3c-efc9-4cf9-ac9e-704fbba9f7e9',
		img: 'talon.jpg',
	},
	{
		name: 'Talon Shrike',
		price: 115,
		id: 'bb31ced6-c5c0-400d-8e1a-5561c9aee30b',
		img: 'talon_shrike.jpg',
	},
	{
		name: 'Nova',
		price: 120,
		id: '1ac5511c-31a9-4a46-8ad0-b88352476e95',
		img: 'nova.jpg',
	},
	{
		name: '350r',
		price: 125,
		id: '56a9f67c-970c-40a1-9815-6c2afa7ca41d',
		img: '350r.jpg',
	},
	{
		name: 'F7C-S Hornet Ghost',
		price: 125,
		id: 'f6e2523a-a648-419b-a4d9-d3042a6b6335',
		img: 'f7c-s_hornet_ghost.jpg',
	},
	{
		name: 'Raft',
		price: 125,
		id: 'b23d2ee6-8c7c-481d-b650-8918adc5af74',
		img: 'raft.jpg',
	},
	{
		name: 'Freelancer DUR',
		price: 135,
		id: 'fcd13df8-2c7d-49c4-8f59-58fb3c35ad18',
		img: 'freelancer_dur.png',
	},
	{
		name: 'Cutless Red',
		price: 135,
		id: 'e22d8968-0354-4fab-b884-6900b4797e9f',
		img: 'cutless_red.jpg',
	},
	{
		name: 'F7C-R Hornet Tracker',
		price: 140,
		id: '86346518-8117-4580-bd1a-9fadd6e14974',
		img: 'f7c_hornet_tracker.jpg',
	},
	{
		name: 'Vulture',
		price: 140,
		id: 'b51cdb1b-027c-4000-8203-9573c7aa747c',
		img: 'vulture.jpg',
	},
	{
		name: 'Ballista',
		price: 140,
		id: 'c995ece3-8c6a-453c-bd69-c353d134cd3c',
		img: 'ballista.jpg',
	},
	{
		name: 'Ballista Snowblind',
		price: 140,
		id: 'f32cbc7e-d11d-4e02-b994-12a216be5a6c',
		img: 'ballista_snowblind.jpg',
	},
	{
		name: 'Ballista Dunestalker',
		price: 140,
		id: '43076c47-2c91-4c59-8531-59b49ab97793',
		img: 'ballista_dunestalker.jpg',
	},
	{
		name: 'Razor',
		price: 145,
		id: 'bd922717-9cc1-479c-b2ab-791f07f0eaff',
		img: 'razor.jpg',
	},
	{
		name: 'Freelancer MAX',
		price: 150,
		id: '0b8e5c2d-20bc-4c56-9885-e8d3790333ec',
		img: 'freelancer_max.png',
	},
	{
		name: 'Retaliator Base',
		price: 150,
		id: '65b1a5bf-1432-4022-a05e-c9567740fe15',
		img: 'retaliator_base.jpg',
	},
	{
		name: 'Razor LX',
		price: 150,
		id: 'fd774164-2ee0-4902-ba76-b2c7b55b25d7',
		img: 'razor_lx.jpg',
	},
	{
		name: 'SRV',
		price: 150,
		id: 'b342cffe-b325-4a15-a11d-89caf784d3da',
		img: 'srv.jpg',
	},
	{
		name: 'Mantis',
		price: 150,
		id: '8a9fc619-2517-44a5-87c9-af5b4f80c12e',
		img: 'mantis.jpg',
	},
	{
		name: 'Prospector',
		price: 155,
		id: '8d354ab3-2fab-47cb-923a-7e77f831aab5',
		img: 'prospector.jpg',
	},
	{
		name: 'Razor EX',
		price: 155,
		id: '7355440b-3dcb-4af3-8391-6e2bde7707ec',
		img: 'razor_ex.jpg',
	},
	{
		name: 'Gladiator',
		price: 165,
		id: '6a81ac82-a902-4216-ad1d-ec8736fce4a3',
		img: 'gladiator.jpg',
	},
	{
		name: 'Khartu-Al',
		price: 170,
		id: '9f59e376-b7d9-4882-a2d9-8b67861eacbb',
		img: 'khartu_al.jpg',
	},
	{
		name: 'Sabre',
		price: 170,
		id: '7ce89e6a-180f-4098-82dc-a602c25461e1',
		img: 'sabre.jpg',
	},
	{
		name: 'Freelancer MIS',
		price: 175,
		id: '020e12e1-4391-4060-8d51-d5b9ee32b5cd',
		img: 'freelancer_mis.jpg',
	},
	{
		name: 'Cutless Blue',
		price: 175,
		id: 'a4584b1b-b187-420c-a60e-531aa4ff20d6',
		img: 'cutless_blue.jpg',
	},
	{
		name: 'F7C Hornet Wildfire',
		price: 175,
		id: '19069deb-ed81-468a-8897-c6b6787ae656',
		img: 'f7c_hornet_wildfire.jpg',
	},
	{
		name: 'F7C-M Super Hornet',
		price: 180,
		id: '7749d3b9-1dff-4076-891f-4233b6d5df45',
		img: 'f7c_super_hornet.jpg',
	},
	{
		name: 'Sabre Comet',
		price: 185,
		id: 'da9b7d38-372c-4a2b-b59f-24eacd243881',
		img: 'sabre_comet.jpg',
	},
	{
		name: 'Constellation Taurus',
		price: 190,
		id: '880d0b47-6770-4b41-8040-cb68380c9605',
		img: 'constellation_taurus.jpg',
	},
	{
		name: 'Hurricane',
		price: 195,
		id: '723bc180-73ef-4887-87db-9b5872b9e30f',
		img: 'hurricane.jpg',
	},
	{
		name: 'F7C-M Heartseeker',
		price: 195,
		id: '28901336-dc05-4c72-85f8-7bc7daf02afd',
		img: 'f7c_hornet_heartseeker.jpg',
	},
	{
		name: 'Vulcan',
		price: 200,
		id: '45d9e7cb-1d20-48f2-9eea-268bf9e68d76',
		img: 'vulcan.jpg',
	},
	{
		name: 'Corsair',
		price: 215,
		id: '9bc576f9-fd74-407b-9437-7d36ce0c3ed4',
		img: 'corsair.jpg',
	},
	{
		name: 'Terrapin',
		price: 220,
		id: 'c072c6f3-b817-424f-81d5-396de2b89f13',
		img: 'terrapin.jpg',
	},
	{
		name: 'Defender',
		price: 220,
		id: 'a44766ba-d1ae-4118-a807-de3926d814b5',
		img: 'defender.jpg',
	},
	{
		name: "San'Tok.Yai",
		price: 220,
		id: '4c305861-d8d1-4151-ac63-e3a14dcbf13f',
		img: 'san_tok_yai.jpg',
	},
	{
		name: 'Scorpius',
		price: 220,
		id: 'a93423c0-e9a6-43fc-925d-6143206eeaa1',
		img: 'scorpius.jpg',
	},
	{
		name: 'Railen',
		price: 225,
		id: 'f2fa5bb1-8a11-4650-8bed-2350695097d6',
		img: 'railen.jpg',
	},
	{
		name: 'Vanguard Hoplite',
		price: 235,
		id: 'f46ff95d-5ca6-49ea-93b7-3ac1ef293852',
		img: 'vanguard_hoplite.jpg',
	},
	{
		name: 'Constellation Andromeda',
		price: 240,
		id: '1d7166ce-677a-499d-aaa7-544a2d00fc85',
		img: 'constellation.jpg',
	},
	{
		name: '400i',
		price: 250,
		id: '4a4d2c7a-eaf4-4f5d-aeca-2b47ffdf517b',
		img: '400i.jpg',
	},
	{
		name: 'Ares Inferno',
		price: 250,
		id: '5c1c517c-1bdd-402b-8e74-1a3c4e302447',
		img: 'ares_inferno.jpg',
	},
	{
		name: 'Ares Ion',
		price: 250,
		id: '2dbbfdbe-f59d-4fa1-92f7-ee99ed00a29b',
		img: 'ares_ion.jpg',
	},
	{
		name: 'Hull C',
		price: 250,
		id: 'f57a0e06-b6d7-4613-bc95-1094bd09c291',
		img: 'hull_c.jpg',
	},
	{
		name: 'Apollo Triage',
		price: 250,
		id: '961dfb1c-671a-462c-a943-c022cc5224b9',
		img: 'apollo_triage.jpg',
	},
	{
		name: 'Vanguard Warden',
		price: 260,
		id: 'a663dd4f-9a15-4960-9f50-531443b119a7',
		img: 'vanguard_warden.jpg',
	},
	{
		name: 'Mercury Star Runner',
		price: 260,
		id: '4e54e13c-9e93-423b-bf0e-9727333ecae0',
		img: 'mercury_star_runner.jpg',
	},
	{
		name: 'Retaliator Bomber',
		price: 275,
		id: 'e294a560-551a-46f0-8722-6748e8e340f9',
		img: 'retaliator_bomber.jpg',
	},
	{
		name: 'Vanguard Sentinel',
		price: 275,
		id: '0e0b3ea0-f12f-4ca0-b351-4b253a104f91',
		img: 'vanguard_sentinel.jpg',
	},
	{
		name: 'Blade',
		price: 275,
		id: 'f323e297-c54f-426f-a2c3-eb7243a7279f',
		img: 'blade.jpg',
	},
	{
		name: 'Apollo Medivac',
		price: 275,
		id: '38877eb7-548b-4d8e-9c1b-5254230c6cd3',
		img: 'apollo_medivac.jpg',
	},
	{
		name: 'Vanguard Harbinger',
		price: 290,
		id: 'a38f9c2d-32ac-4699-97b5-7341e621d44e',
		img: 'vanguard_harbinger.jpg',
	},
	{
		name: 'Scythe',
		price: 300,
		id: 'd17b9934-234e-48f7-8a78-ecffe42a87dd',
		img: 'scythe.jpg',
	},
	{
		name: 'Starfarer',
		price: 300,
		id: 'bc1f982e-890f-43ac-a2cf-72191af07a1a',
		img: 'starfarer.jpg',
	},
	{
		name: 'Eclipse',
		price: 300,
		id: '72d23421-2339-4a25-9ff3-1ff4bff72524',
		img: 'eclipse.jpg',
	},
	{
		name: 'Constellation Aquila',
		price: 310,
		id: '11e8c06b-8d58-4070-af58-8fa81effe617',
		img: 'constellation.jpg',
	},
	{
		name: 'Mole',
		price: 315,
		id: 'c8644005-af39-48d4-8bd0-e0f607bf52f5',
		img: 'mole.jpg',
	},
	{
		name: 'Mole Carbon Edition',
		price: 315,
		id: '4ab17670-b5a7-4639-b5aa-f8e6d5851636',
		img: 'mole_carbon_edition.jpg',
	},
	{
		name: 'Mole Talus Edition',
		price: 315,
		id: 'c120fda6-895c-41ab-ac8b-46f43702c634',
		img: 'mole_talus_edition.jpg',
	},
	{
		name: 'Redeemer',
		price: 325,
		id: '7c5ea876-89ea-45e2-8245-3d206bda0cf8',
		img: 'redeemer.jpg',
	},
	{
		name: 'Caterpillar',
		price: 330,
		id: 'fb804b92-2728-49c8-8f90-e8fba2249490',
		img: 'caterpiller.jpg',
	},
	{
		name: 'Caterpillar Pirate Edition',
		price: 330,
		id: '5db2447b-73b8-4bb5-afc6-947d4927c8ea',
		img: 'caterpiller_pirate_edition.jpg',
	},
	{
		name: 'Caterpillar Best In Show Edition',
		price: 330,
		id: '5f5d8b9e-449e-44fa-9b43-de4e68e91bf2',
		img: 'caterpiller_best_in_show_edition.jpg',
	},
	{
		name: 'Starfarer Gemini',
		price: 340,
		id: 'eb7d0989-2480-40d2-bdd2-512efb2483b6',
		img: 'starfarer_gemini.jpg',
	},
	{
		name: 'Constellation Phoenix',
		price: 350,
		id: '4f60c384-c1ac-4d14-8d79-cce2af8bbd55',
		img: 'constellation.jpg',
	},
	{
		name: 'Hull D',
		price: 350,
		id: '3fb2bbbc-797e-47c0-bab4-e50e9d03a42f',
		img: 'hull_d.jpg',
	},
	{
		name: 'Glaive',
		price: 350,
		id: '67675cb4-24e4-49ca-9040-dcc7d41d5048',
		img: 'glaive.jpg',
	},
	{
		name: 'Endeavor',
		price: 350,
		id: 'eef14fe7-9439-441d-9fb4-4ba8beb2eca1',
		img: 'endeavor.jpg',
	},
	{
		name: 'Crucible',
		price: 350,
		id: '941e7875-a000-4d8c-a566-7e8cf3d56202',
		img: 'crucible.jpg',
	},
	{
		name: 'Constellation Phoenix Emerald',
		price: 350,
		id: 'b3c38ff3-0050-4326-9af8-b8279cb742b2',
		img: 'constellation.jpg',
	},
	{
		name: 'Valkyre',
		price: 375,
		id: 'e2782bb6-df97-43a8-8c60-4e2176bfc995',
		img: 'valkyre.jpg',
	},
	{
		name: 'Valkyre Liberator Edition',
		price: 375,
		id: '4ed8dd91-5388-49bb-8c25-1c957595c3ab',
		img: 'valkyre_liberator_edition.jpg',
	},
	{
		name: 'Reclaimer',
		price: 400,
		id: '3ce55b4a-ef57-489c-bfdd-68c24b01d534',
		img: 'reclaimer.jpg',
	},
	{
		name: 'Genesis Starliner',
		price: 400,
		id: 'aecd7a97-6c8c-4c84-b40a-e2a6a5f0f2aa',
		img: 'genesis_starliner.jpg',
	},
	{
		name: 'C2 Hercules Starlifter',
		price: 400,
		id: '997083dd-6de8-4ccd-a532-ee2a2f0e1fcb',
		img: 'c2_hercules.jpg',
	},
	{
		name: 'Reclaimer Best In Show',
		price: 400,
		id: '958430d3-e1b3-4f06-8cb6-6b76cb69046e',
		img: 'reclaimer_best_in_show.jpg',
	},
	{
		name: '600i Touring',
		price: 435,
		id: '9a85d768-8693-483c-b1ce-9fd07823bb91',
		img: '600i_touring.jpg',
	},
	{
		name: 'Prowler',
		price: 440,
		id: 'd2db296e-c3ee-4fc9-b89f-66f168c9b1df',
		img: 'prowler.jpg',
	},
	{
		name: '600i Explorer',
		price: 475,
		id: 'dd5205d9-af45-4360-90ec-7145f4e627c9',
		img: '600i_explorer.jpg',
	},
	{
		name: 'M2 Hercules Starlifter',
		price: 520,
		id: '4133b58a-c02d-4721-96ac-1f5f89e43912',
		img: 'm2_hercules.jpg',
	},
	{
		name: 'Merchantman',
		price: 550,
		id: 'f238cf84-f0b9-4501-8f50-7106df3aa877',
		img: 'merchantman.jpg',
	},
	{
		name: 'Liberator',
		price: 575,
		id: '07620dfe-f5bb-4f85-88c8-ede504d40d20',
		img: 'liberator.png',
	},
	{
		name: 'Orion',
		price: 575,
		id: 'be478678-fed7-463e-88eb-a5bd90caecb5',
		img: 'orion.jpg',
	},
	{
		name: 'Carrack',
		price: 600,
		id: 'b2ed050c-ddfe-4bd9-8277-5427713f3a39',
		img: 'carrack.jpg',
	},
	{
		name: 'Carrack W/C8X',
		price: 620,
		id: '7cf84fba-f44f-40a0-b283-6646f38cdab7',
		img: 'carrack_w_c8x.jpg',
	},
	{
		name: 'Carrack Expedition',
		price: 625,
		id: '595939cd-2aa8-4c33-9002-8f4b9f085bc6',
		img: 'carrack_expedition.jpg',
	},
	{
		name: 'Carrack Expedition W/C8X',
		price: 645,
		id: '5b1be48b-70ce-4d65-8cf7-0a43037e61da',
		img: 'carrack_expedition_w_c8x.jpg',
	},
	{
		name: 'Hull E',
		price: 650,
		id: '42cdac52-0e48-4322-a169-8a3704bc4d09',
		img: 'hull_e.jpg',
	},
	{
		name: 'Perseus',
		price: 675,
		id: '96e887ea-0b6b-4da9-a2dd-acc290fae092',
		img: 'perseus.jpg',
	},
	{
		name: 'Odyssey',
		price: 700,
		id: 'be74498c-c28a-4b41-bc15-fbb728f3e60a',
		img: 'odyssey.jpg',
	},
	{
		name: 'Hammerhead',
		price: 725,
		id: 'a3da7646-5fc7-497d-a106-6eb0f631f686',
		img: 'hammerhead.png',
	},
	{
		name: 'Nautilus',
		price: 725,
		id: '9d64480b-f6e1-4351-af0f-01ef05d9c7c8',
		img: 'nautilus.jpg',
	},
	{
		name: 'Nautilus Solstice Edition',
		price: 725,
		id: 'b414d3c8-e234-4d9b-ac70-35a0da422461',
		img: 'nautilus_solstice_edition.jpg',
	},
	{
		name: 'Hammerhead Best In Show',
		price: 725,
		id: '3b5e2ac5-d0ed-44b5-a55c-d7e4cad30074',
		img: 'hammerhead_best_in_show.jpg',
	},
	{
		name: 'A2 Hercules Starlifer',
		price: 750,
		id: '917c7bb6-da42-49a0-9210-295b7e4d94cd',
		img: 'a2_hercules.jpg',
	},
	{
		name: 'Polaris',
		price: 750,
		id: 'cc3983f9-96b7-4ab1-8bfd-c8d1d4921fe9',
		img: 'polaris.jpg',
	},
	{
		name: 'Pioneer',
		price: 850,
		id: 'c0e2707c-2f9a-465b-adf5-07dbd1cb50d9',
		img: 'pioneer.jpg',
	},
	{
		name: '890 Jump',
		price: 950,
		id: '88ef7eef-f15a-460f-86ab-05403a75b1ae',
		img: '890_jump.jpg',
	},
	{
		name: 'Idris-P',
		price: 1500,
		id: '3f734d8d-1686-49b9-a41d-8e22a9d15bbf',
		img: 'idris_p.jpg',
	},
]

export default shipdata
