import sendRSI from 'functions/extension'
import React, { createContext, useEffect } from 'react'
import { useContext } from 'react'
import { useImmerReducer } from 'use-immer'

const rsiReducer = (state, { action, data }) => {
	switch (action) {
		case 'addRSI': {
			return { ...state, user: data }
		}
		case 'ping': {
			return { ...state, extension: data }
		}
		default:
			return state
	}
}

export const RSIContext = createContext()

export const useRSIContext = () => useContext(RSIContext)

const initialState = { user: undefined, extension: {} }

export function RSIScope({ children }) {
	const [rsiData, rsiDispatch] = useImmerReducer(rsiReducer, initialState)

	useEffect(() => {
		if (!rsiData.extension?.loaded) {
			sendRSI({ action: 'ping' }, data => rsiDispatch({ action: 'ping', data }))
		}

		if (rsiData.extension?.loaded && !rsiData.user?.member) {
			sendRSI(
				{
					url: 'https://robertsspaceindustries.com/api/spectrum/auth/identify',
					payload: {},
				},
				res => {
					console.log(res)
					rsiDispatch({ action: 'addRSI', data: res.data })
				}
			)
		}
	}, [rsiDispatch, rsiData.extension?.loaded])

	return <RSIContext.Provider value={{ rsiData, rsiDispatch }}>{children}</RSIContext.Provider>
}

export default useRSIContext
