import React from 'react'

import Toolbar from '@material-ui/core/Toolbar'
import { makeStyles } from '@material-ui/styles'
import { Box } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
	wrapper: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
	content: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: 20,
	},
}))

//This prevents the context from being obscured by the top nav bar.
export default function MainContent({ children }) {
	const classes = useStyles()
	return (
		<Box
			component='main'
			sx={{
				backgroundColor: theme => (theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900]),
				flexGrow: 1,
				height: '100vh',
				overflow: 'auto',
				backgroundImage: theme =>
					theme.palette.mode === 'dark'
						? `linear-gradient(rgba(64,64,64,0.95),rgba(58,58,58,0.95)), url(./images/background1.jpg)`
						: `linear-gradient(rgba(180,180,180,0.95),rgba(200,200,200,0.95)), url(./images/background1.jpg)`,
			}}
		>
			<main className={classes.wrapper}>
				<Toolbar />
				<div className={classes.content}>{children}</div>
			</main>
		</Box>
	)
}
