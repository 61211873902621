import React, { memo, useCallback, useMemo, useState } from 'react'
import { CardContent, Typography, CardActions, Card } from '@material-ui/core'

import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import Tooltip from '@material-ui/core/Tooltip'
import Box from '@material-ui/system/Box'

import shipdata from 'utils/shipdata'
import useContextCCU from './CCUContext'
import CCUModal from './CCUModal'
import Modal from 'components/modals/Modal'

export default function CCUCustom() {
	const { ccuData, ccuDispatch } = useContextCCU()

	const { ccus, scale, minimise, stack } = ccuData

	// const [images, setImages] = useState({})
	const [editCCU, setEditCCU] = useState()
	const [deleteIdCCU, setDeleteIdCCU] = useState()

	const grids = useMemo(() => {
		const ccusCopy = JSON.parse(JSON.stringify(ccus))
		const ccuList = !stack
			? ccusCopy
			: ccusCopy.filter((ccu, i, arr) => {
					console.log('grids', { ccu })
					const index = arr.findIndex(item => item.from.id === ccu.from.id && item.to.id === ccu.to.id)
					const dupe = index !== i
					if (dupe) {
						if (arr[index].qty) {
							arr[index].qty++
						} else {
							arr[index].qty = 2
						}
					}
					return !dupe
			  })

		const shipLists = []

		let maxValue = 0
		ccuList.forEach(ccu => {
			const { from, to } = ccu

			console.log({ ccu })

			const shipFrom = shipdata.find(ship => ship.id === from.id)
			const shipTo = shipdata.find(ship => ship.id === to.id)

			ccu.from = JSON.parse(JSON.stringify(shipFrom))
			ccu.to = JSON.parse(JSON.stringify(shipTo))
			if (shipFrom.price > shipTo.price) {
				const shipFromPrice = shipFrom.price
				const shipToPrice = shipTo.price
				ccu.from.price = shipToPrice
				ccu.to.price = shipFromPrice
			}

			maxValue = Math.max(maxValue, shipFrom.price)
			maxValue = Math.max(maxValue, shipTo.price)

			if (shipLists.length === 0) return shipLists.push([ccu])

			outerloop: for (let i = 0; i < shipLists.length; i++) {
				const list = shipLists[i]
				for (let l = 0; l < list.length; l++) {
					const listedFrom = shipdata.find(ship => ship.id === list[l].from.id)
					const listedTo = shipdata.find(ship => ship.id === list[l].to.id)

					if (shipFrom.price < listedFrom.price && shipTo.price <= listedFrom.price && listedFrom.id !== listedTo.id) {
						list.splice(l, 0, ccu)
						break outerloop
					} else if (listedFrom.id === listedTo.id && (shipFrom.price < listedFrom.price || shipTo.price < listedFrom.price || shipFrom.price < listedTo.price || shipTo.price < listedTo.price)) {
						break //If ccu is cheaper than an owned ship
					} else if (shipFrom.id === shipTo.id && (shipFrom.price > listedFrom.price || shipTo.price > listedFrom.price || shipFrom.price > listedTo.price || shipTo.price > listedTo.price)) {
						break //if ccu is more expensive than an owned ship
					} else if (
						(listedFrom.price < shipFrom.price && listedTo.price > shipFrom.price) ||
						(listedFrom.price < shipTo.price && listedTo.price > shipTo.price) ||
						(shipFrom.price < listedFrom.price && shipTo.price > listedFrom.price) ||
						(shipFrom.price < listedTo.price && shipTo.price > listedTo.price)
					) {
						break
					} else if (shipFrom.id === listedFrom.id && shipTo.id === listedTo.id) {
						break
					} else if (shipFrom.price === listedFrom.price && shipTo.price === listedTo.price) {
						break
					}
				}

				const lastListFrom = shipdata.find(ship => ship.id === list[list.length - 1].from.id)
				const lastListTo = shipdata.find(ship => ship.id === list[list.length - 1].to.id)
				if ((shipFrom.price >= lastListTo.price || (shipFrom.price === lastListFrom.price && shipFrom.id === lastListTo.id)) && shipTo.price > lastListTo.price) {
					list.push(ccu)
					break
				}

				if (i === shipLists.length - 1) {
					shipLists.push([ccu])
					break
				}
			}
		})

		const grids = {}
		for (let price = 0; price <= maxValue; price += scale) {
			grids[price] = []
			for (let l = 0; l < shipLists.length; l++) {
				const list = shipLists[l]
				grids[price][l] = undefined
				for (let c = 0; c < list.length; c++) {
					const ccu = list[c]
					if (ccu.from.price && ccu.to.price && ccu.from.price === price && ccu.from.price === ccu.to.price) {
						if (!grids[price][l]) grids[price][l] = []
						grids[price][l].push({ ...ccu, type: 'start' })
					} else if (ccu.from.price === price) {
						if (!grids[price][l]) grids[price][l] = []
						grids[price][l].push({ ...ccu, type: 'from' })
					} else if (ccu.to.price === price) {
						if (!grids[price][l]) grids[price][l] = []
						grids[price][l].push({ ...ccu, type: 'to' })
					}
				}
			}
		}

		if (minimise) {
			for (const col in grids) {
				if (!grids[col].filter(cell => cell).length) delete grids[col]
			}
		}

		return grids
	}, [ccus, stack, minimise, scale])

	const deleteCCU = useCallback(() => {
		ccuDispatch({ action: 'deleteCCU', id: deleteIdCCU })
		setDeleteIdCCU(false)
	}, [ccuDispatch, deleteIdCCU])

	const Content = memo(() => {
		return (
			<Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}>
				{Object.entries(grids).map(([key, col]) => (
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							position: 'relative',
							width: theme =>
								(col.reduce(
									(max, row) =>
										Math.max(max, row?.length || 0, row?.reduce((total, ccu) => (total ? total : ccu.from?.id !== ccu.to?.id && ccu.from?.price === ccu.to?.price ? 2 : 0), 0) || 0),
									0
								) || 1) * 150,
						}}
						key={key}
					>
						<Card
							sx={{
								marginRight: '20px',
								paddingLeft: '10px',
							}}
							elevation={5}
						>
							${key}
						</Card>
						{col.map((row, i) => {
							if (!row) return false
							row = row.sort((a, b) => b.type.localeCompare(a.type)) //sorts the 'to' of a prev ccu and the 'from' of the current ccu.

							return row?.map((ccu, c) => {
								if (ccu.type === 'to') return false
								const widths =
									ccu.from?.id !== ccu.to?.id && ccu.from?.price === ccu.to?.price
										? 2
										: ccu.type === 'start'
										? 1
										: Object.entries(grids)
												.filter(([key, col]) => key >= ccu.from?.price && key < ccu.to?.price)
												.reduce(
													(total, [k, c]) =>
														total +
														c.reduce((max, r) => Math.max(max, r?.length || 1, r?.filter(ccu => ccu.from?.id !== ccu.to?.id && ccu.from?.price === ccu.to?.price).length ? 2 : 0), 0),
													0
												) +
										  1 -
										  row.findIndex(c => c === ccu)

								return (
									<Card
										sx={{
											width: widths ? widths * 150 - 20 : 130,
											display: 'flex',
											height: 100,
											left: row.findIndex(c => c === ccu) * 150,
											top: i * 120 + 40,
											position: 'absolute',
											'&:hover .actions': {
												opacity: 1,
											},
										}}
										elevation={5}
										key={ccu.id}
									>
										<CardContent
											sx={{
												padding: '0 !important',
												flex: 1,
												display: 'flex',
												justifyContent: 'space-between',
												position: 'relative',
											}}
										>
											<Box
												sx={{
													display: 'flex',
													flexDirection: 'column',
													width: '140px',
													backgroundImage: theme =>
														`url(./images/${ccu.from.img}), ${
															theme.palette.mode === 'dark'
																? `linear-gradient(to right, rgba(0,0,0,0.5) 0%, rgba(0, 0, 0 ,0.5) 100%)`
																: `linear-gradient(to right, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0.5) 100%)`
														}`,
													backgroundBlendMode: 'overlay',
													backgroundSize: '200px auto',
													backgroundPosition: '50%',
													maskImage: widths === 1 ? undefined : `linear-gradient(80deg, black 65%, transparent 90%)`,
													transition: 'all 0.3s ease-in-out',
												}}
											>
												<Typography component='div' m={1.2}>
													{ccu.from.name}
												</Typography>
											</Box>
											<Box
												sx={{
													display: 'flex',
													flexDirection: 'column',
													width: '140px',
													backgroundImage: theme =>
														`url(./images/${ccu.to.img}), ${
															theme.palette.mode === 'dark'
																? `linear-gradient(to left, rgba(0,0,0,0.5) 0%, rgba(0, 0, 0, 0.5) 100%)`
																: `linear-gradient(to left, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0.5) 100%)`
														}`,
													backgroundBlendMode: 'overlay',
													backgroundSize: '200px auto',
													backgroundPosition: '50%',
													maskImage: `linear-gradient(260deg, black 65%, transparent 90%)`,
													transition: 'all 0.3s ease-in-out',
												}}
											>
												<Typography
													component='div'
													m={1.2}
													sx={{
														display: 'flex',
														justifyContent: 'flex-end',
														textAlign: 'right',
													}}
												>
													{ccu.to.name}
												</Typography>
											</Box>
											<CardActions
												className='actions'
												sx={{
													position: 'absolute',
													bottom: '-5px',
													left: '-5px',
													opacity: 0,
													transition: 'all 0.1s linear',
													display: 'flex',
												}}
											>
												<Tooltip title='Delete CCU' arrow>
													<IconButton
														aria-label='delete ccu'
														onClick={() => {
															setDeleteIdCCU(ccu.id)
														}}
													>
														<DeleteOutlineIcon />
													</IconButton>
												</Tooltip>
												<Tooltip title='Edit CCU' arrow>
													<IconButton
														aria-label='edit ccu'
														onClick={() => {
															setEditCCU(ccu.id)
														}}
													>
														<EditIcon />
													</IconButton>
												</Tooltip>
											</CardActions>
											{ccu.qty && (
												<CardActions
													className='actions'
													sx={{
														position: 'absolute',
														bottom: '-5px',
														right: '-5px',
														opacity: 0,
														transition: 'all 0.1s linear',
														display: 'flex',
													}}
												>
													<Box p={1}>x{ccu.qty}</Box>
												</CardActions>
											)}
										</CardContent>
									</Card>
								)
							})
						})}
					</Box>
				))}
			</Box>
		)
	}, [grids])

	return (
		<>
			<CCUModal closeModal={() => setEditCCU(false)} open={!!editCCU} editCCU={editCCU} />
			<Modal
				title='Delete CCU?'
				body={`Are you sure you want to delete this CCU?`}
				onClose={() => setDeleteIdCCU(false)}
				buttons={[
					{ text: 'Cancel', onClick: () => setDeleteIdCCU(false) },
					{ text: 'Delete', color: 'error', variant: 'contained', onClick: deleteCCU },
				]}
				open={!!deleteIdCCU}
			></Modal>
			<Content />
		</>
	)
}
