import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'

import useMediaQuery from '@material-ui/core/useMediaQuery'

export default function TopBar({ setIsOpen }) {
	const desktop = useMediaQuery('(min-width:640px)', { noSsr: true })

	return (
		<AppBar position='fixed' sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}>
			<Toolbar sx={{ paddingLeft: '6px !important' }}>
				{!desktop && (
					<IconButton aria-label='menu' size='large' onClick={() => setIsOpen(isOpen => !isOpen)}>
						<MenuIcon fontSize='inherit' />
					</IconButton>
				)}
				<Typography variant='h6' sx={{ flex: 1, display: 'flex', justifyContent: 'center' }} noWrap>
					CCU Tracker
				</Typography>
			</Toolbar>
		</AppBar>
	)
}
